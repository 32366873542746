import {ExtensibleEntityDto} from './ExtensibleEntityDto'
import {PagedAndSortedResultRequestDto} from './PagedAndSortedResultRequestDto'
import {Guid} from 'guid-typescript';

export interface MuscleSiteDto extends ExtensibleEntityDto <Guid> {
  name: string
}

export class CreateOrUpdateMuscleSiteDto {
  public name: string = ''
}

export class GetListMuscleSiteDto extends PagedAndSortedResultRequestDto {
  public filter: string = ''
}
